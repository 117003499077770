import { useEffect, useState } from "react"

const Activity = () => {
    const title = 'Promotions'
    const time = 'November 11, 2024, 10:10:10 AM.'
    // const selectData = useEffect(()=>{
    //     return data.filter(item => item.label === selected)[0].title
    // },[selected])
    let publicUrl = process.env.PUBLIC_URL+'/'

    return (
        <>
            <div style={{position:"relative",paddingTop:'70px'}}>
                <div className="container">
                    <div style={{marginBottom:"45px"}}>    
                        <h3>{title}</h3>
                        <h4>{time}</h4>
                    </div>
                    <div>
                        <img style={{width:"100%"}} src={publicUrl+'assets/img/jianzhu.png'}></img>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Activity