import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
class CareerOpportunities extends Component {
  render() {
    const { type } = this.props;
    const sectionStyle = {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-start',
      paddingBottom: '90px'
    };

    const headingContainerStyle = {
      flex: '1',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center'
    };

    const headingStyle = {
      fontSize: '2rem',
      fontFamily: 'Georgia, serif',
      color: '#2c3e50',
      marginBottom: '10px'
    };

    const subheadingStyle = {
      fontSize: '2.5rem',
      fontFamily: 'Georgia, serif',
      color: '#edc987',
      marginBottom: '20px'
    };

    const cardContainerStyle = {
      flex: '2',
      padding: '20px, 15px, 20px, 20px',
      display: 'flex',
      flexDirection: 'column',
      gap: '20px'
    };

    const cardStyle = {
      display: 'flex',
      flexDirection: 'column',
      padding: '20px',
      backgroundColor: '#f9f9f9',
      color: '#2c3e50',
      borderRadius: '8px',
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'
    };

    const jobTitleStyle = {
      fontSize: '1.5rem',
      fontWeight: 'bold',
      marginBottom: '10px'
    };

    const jobDescriptionStyle = {
      fontSize: '1rem',
      marginBottom: '20px'
    };

    const buttonStyle = {
      fontSize: '1rem',
      color: '#fff',
      display:'flex',
      alignItems:'center',
      justifyContent:'center',
      borderRadius:"4px",
      // border: 'none',
      backgroundColor: 'var(--main-color-one)',
      cursor: 'pointer',
      width:'90px',
      height: '48px',
      marginBottom:"20px"
    };

    const jobs = {
      title: '任职要求：',
      title2: '岗位职责：',
      date: 'Feb 14, 2024',
      description: [
        '5年以上房地产领域相关工作经验;',
        '具备一定的管理能力，有效协调团队，提高组织效率和业绩;',
        '熟练应用办公软件，高效处理文档、数据、合同等文案工作;',
        '具备独立解决问题的能力，能够监督、支持并高效完成销售流程中的各个环节;',
        '具备出色的中英文理解和表达能力。'
      ],
      duty: [
        '管理销售团队，制定、监督执行并完成团队的工作计划;',
        '负责销售管理过程中的协调和沟通工作:公司合作方、开发商以及公司内部其它部门;',
        '负责把控销售流程中的各个环节，确保各项工作顺利完成。'
      ]
    };
    const email = 'mailto:helen.wang@helenrealtysa.com'
    const { t, i18n } = this.props;
    console.log(i18n, '123');
    return (
      <div className="container" >
        <div style={{...headingStyle,fontSize:"3rem",paddingTop:type=='poster'?'20px':"0"}}>Available Positions</div>
        <div style={{fontSize:"30px",marginBottom:"30px",color: 'rgb(44, 62, 80)'}}>Sales Team Manager/SalesTeam Management Officer</div>
        <div style={cardStyle}>
          <div style={headingStyle}>{jobs.title}</div>
          <div>{jobs.description.map(item => {
            return (
              <div style={{fontSize:'16px'}}>{item}</div>
            )
          })}</div>
        </div>
        <div style={{...cardStyle,margin:"20px 0"}}>
          <div style={headingStyle}>{jobs.title2}</div>
          <div>{jobs.duty.map(item => {
            return (
              <div style={{fontSize:'16px'}}>{item}</div>
            )
          })}</div>
        </div>
        {type==='poster'&&<a style={buttonStyle} href={email}>申请</a>}
        {type!=='poster'&& <Link  style={buttonStyle} to="/poster">申请</Link>}
      </div>
    );
  }
}

export default withTranslation()(CareerOpportunities);