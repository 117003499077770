import React, { Component } from 'react';
import { withTranslation } from 'react-i18next'; 
class CareerOpportunities extends Component {
  render() {
    const sectionStyle = {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-start',
      paddingBottom: '90px'
    };

    const headingContainerStyle = {
      flex: '1',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center'
    };

    const headingStyle = {
      fontSize: '3rem',
      fontFamily: 'Georgia, serif',
      color: '#2c3e50',
      marginBottom: '20px'
    };

    const subheadingStyle = {
      fontSize: '2.5rem',
      fontFamily: 'Georgia, serif',
      color: '#edc987',
      marginBottom: '20px'
    };

    const cardContainerStyle = {
      flex: '2',
      padding: '20px, 15px, 20px, 20px',
      display: 'flex',
      flexDirection: 'column',
      gap: '20px'
    };

    const cardStyle = {
      display: 'flex',
      flexDirection: 'column',
      padding: '20px',
      backgroundColor: '#f9f9f9',
      color: '#2c3e50',
      borderRadius: '8px',
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'
    };

    const jobTitleStyle = {
      fontSize: '1.5rem',
      fontWeight: 'bold',
      marginBottom: '10px'
    };

    const jobDescriptionStyle = {
      fontSize: '1rem',
      marginBottom: '20px'
    };

    const buttonStyle = {
      fontSize: '1rem',
      color: '#edc987',
      border: 'none',
      backgroundColor: 'transparent',
      cursor: 'pointer'
    };

    const jobs = [
      {
        title: '56',
        date: 'Feb 14, 2024',
        description: '57'
      },
      {
        title: '56',
        date: 'Feb 14, 2024',
        description: '57'
      }
    ];
  const zhJobs =  [
    {
      title: '56',
      date: 'Feb 14, 2024',
      description: '57'
    },
    {
      title: '58',
      date: 'Feb 14, 2024',
      description: '59'
    },
    {
      title: '60',
      date: 'Feb 14, 2024',
      description: '61'
    },
    {
      title: '62',
      date: 'Feb 14, 2024',
      description: '63'
    }
  ];
  const { t, i18n } = this.props;
  console.log(i18n,'123');
    return (
      <div className="container" style={sectionStyle}>
        <div style={headingContainerStyle}>
          <h1 style={headingStyle}>Current Career</h1>
          <h2 style={subheadingStyle}>Opportunities</h2>
        </div>
        <div style={cardContainerStyle}>
          {(i18n.language === 'zh' ? zhJobs : jobs).map((job, index) => (
            <div key={index} style={cardStyle}>
              <div style={jobTitleStyle}>
                {t(job.title)}
                <span style={{ float: 'right', fontSize: '0.9rem', color: '#edc987' }}>Added on {job.date}</span>
              </div>
              <div style={jobDescriptionStyle}>{t(job.description)}</div>
              <button style={buttonStyle}>
                Read More
                <span style={{ marginLeft: '5px', fontSize: '1.2rem' }}>&#8594;</span>
              </button>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default withTranslation()(CareerOpportunities);